<template>
  <div v-if="true">
    <a-row type="flex" class="iotplt-margin-bottom-15px">
      <a-col flex="70px">
        <a-icon
          type="transaction"
          :style="{fontSize: '55px', color: 'rgb(95, 166, 248)' }"
        />
      </a-col>

      <a-col flex="200px">
        <a-spin :spinning="loadingCommission">
          <a-statistic
            class="iotplt-statistic"
            title="佣金余额"
            prefix="￥"
            :precision="2"
            :value="commission"
          />
        </a-spin>
      </a-col>
    </a-row>

    <a-row type="flex">
      <a-col>
        <a-form
          class="iotplt-compact-form"
          :form="form"
          layout="vertical"
          @submit="handleSubmit"
        >
          <a-form-item label="提现金额(元)">
            <a-input-number
              style="width: 100%;"
              :min="0.01"
              :max="commission"
              :step="0.01"
              :precision="2"
              v-decorator="['amount', {
                rules: [
                  { required: true, message: '请输入提现金额' },
                ]
              }]"
            />
          </a-form-item>

          <a-form-item label="提现到">
            <a-radio-group
              @change="handlePayTypeChange"
              v-decorator="['pay_way', {
                rules: [
                  { required: true, message: '请选择支付平台' }
                ]
              }]"
            >
              <a-radio-button
                class="pay-way-btn"
                value="company_bank"
              >
                <a-row class="bank">
                  <a-col class="left">
                    <div>对公打款</div>
                  </a-col>
                </a-row>
              </a-radio-button>

              <a-radio-button
                class="pay-way-btn"
                value="personal_bank"
              >
                <a-row class="bank">
                  <a-col class="left">
                    <div>个人打款</div>
                    <div style="color:red;font-size:14px;margin-top:-7px;">6.5%手续费</div>
                  </a-col>
                </a-row>
              </a-radio-button>

              <a-radio-button
                v-if="false"
                class="pay-way-btn wechat-pay-btn"
                value="wechat_pay"
              />

              <a-radio-button
                v-if="false"
                class="pay-way-btn alipay-btn"
                value="alipay"
              />
            </a-radio-group>
          </a-form-item>

          <div v-if="isWechatPay || isAliPay">
            <a-form-item
              label="微信/支付宝账号"
            >
              <a-input
                v-decorator="['pay_account', {
                  rules: [
                    { required: true, message: '请输入账号' },
                    { max: 30, message: '最多30个字符' }
                  ]
                }]"
              />
            </a-form-item>
          </div>

          <div v-if="isCompanyBank || isPersonalBank">
            <a-form-item
              label="户名/姓名"
            >
              <a-input
                v-decorator="['account_name', {
                  rules: [
                    { required: true, message: '请输入户名/姓名' },
                    { max: 30, message: '最多30个字符' }
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item
              label="开户行及支行"
            >
              <a-input
                v-decorator="['bank_name', {
                  rules: [
                    { required: true, message: '请输入开户行及支行' },
                    { max: 30, message: '最多30个字符' }
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item
              label="账号"
            >
              <a-input
                v-decorator="['bank_no', {
                  rules: [
                    { required: true, message: '请输入账号' },
                    { max: 30, message: '最多30个字符' },
                    { pattern: /^\d*$/, message: '账号格式不正确' }
                  ]
                }]"
              />
            </a-form-item>
          </div>

          <div v-if="isCompanyBank">
            <a-form-item label="发票" required>
              <upload-custom-file
                accept=".pdf"
                hint="支持pdf格式，20MB以内"
                :max-size="20*1024*1024"
                @change="handleInvoiceChange"
                v-decorator="['invoice', {
                  rules: [
                    { validator: checkInvoice }
                  ],
                }]"
              />
            </a-form-item>
          </div>

          <div v-if="isPersonalBank">
            <a-form-item label="身份证照片(正面)" required>
              <span
                slot="extra"
                class="tip-text"
              >建议添加水印后上传，支持.jpg .jpeg .bmp .png格式照片，大小不超过10M</span>
              <upload-image
                :is-add-watermark="isAddWatermark"
                :default-file-list="frontIdentityCard"
                :accept="accept"
                @change="handleFrontIdentityCard"
                v-decorator="['front_identity_card',{
                  rules:[
                    {validator: checkFrontIdentityCard}
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item label="身份证照片(反面)" required>
              <span
                class="tip-text"
                slot="extra"
              >建议添加水印后上传，支持.jpg .jpeg .bmp .png格式照片，大小不超过10M</span>
              <upload-image
                :is-add-watermark="isAddWatermark"
                :default-file-list="backIdentityCard"
                :accept="accept"
                @change="handleBackIdentityCardChange"
                v-decorator="['back_identity_card',{
                  rules:[
                    {validator: checkBackIdentityCard}
                  ]
                }]"
              />
            </a-form-item>
          </div>

          <a-form-item>
            <a-button
              :loading="submitting"
              type="primary"
              html-type="submit"
            >
              提交申请
            </a-button>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
  <div v-else>
    <span style="color:red;">没有该页面的权限</span>
  </div>
</template>

<script>
import UploadImage from '@/components/Upload/Image'
import UploadCustomFile from '@/components/Upload/CustomFile'
import { findAgentFinanceOverview, withdrawalAgentCommission } from '@/api/agent'

export default {
  name: 'RechargeBalance',
  components: {
    UploadImage,
    UploadCustomFile
  },
  data() {
    return {
      commission: undefined,
      loadingCommission: true,
      isWechatPay: false,
      isAliPay: false,
      isCompanyBank: false,
      isPersonalBank: false,

      invoice: undefined, // 发票

      frontIdentityCard: undefined, // 身份证（正面）
      backIdentityCard: undefined, // 身份证（背面）
      isAddWatermark: true, // 是否添加水印
      accept: '.jpg,.jpeg,.bmp,.png',

      submitting: false,
      form: this.$form.createForm(this, { name: 'balance_recharge' })
    }
  },
  computed: {
    isMerchant() {
      return this.$store.getters.userRole === 'merchant'
    }
  },
  created() {
    this.fetchBalanceStatistic()
  },
  methods: {
    fetchBalanceStatistic() {
      this.loadingCommission = true
      findAgentFinanceOverview(this.$store.getters.userAgentId).then((res) => {
        if (res.code === 0) {
          this.commission = res.data.commission_available
        }
        this.loadingCommission = false
      })
    },

    handlePayTypeChange(e) {
      this.isWechatPay = false
      this.isAliPay = false
      this.isCompanyBank = false
      this.isPersonalBank = false

      switch (e.target.value) {
        case 'wechat_pay':
          this.isWechatPay = true
          break
        case 'alipay':
          this.isAliPay = true
          break
        case 'company_bank':
          this.isCompanyBank = true
          break
        case 'personal_bank':
          this.isPersonalBank = true
          break
      }
    },

    handleFrontIdentityCard(fileList) {
      if (Object.keys(fileList).length > 0) {
        this.frontIdentityCard = fileList
      } else {
        this.frontIdentityCard = undefined
      }
    },

    handleBackIdentityCardChange(fileList) {
      if (Object.keys(fileList).length > 0) {
        this.backIdentityCard = fileList
      } else {
        this.backIdentityCard = undefined
      }
    },

    handleInvoiceChange(fileList) {
      if (Object.keys(fileList).length > 0) {
        this.invoice = fileList
      } else {
        this.invoice = undefined
      }
    },

    checkInvoice(rule, value, callback) {
      if (!this.invoice || this.invoice[0].status === 'error') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请上传发票')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    // 检查法人身份证照片（正面）
    checkFrontIdentityCard(rule, value, callback) {
      if (!this.frontIdentityCard || this.frontIdentityCard[0].status === 'error') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请上传身份证照片（正面）')
        return
      }

      callback()
    },

    // 检查法人身份证照片（背面）
    checkBackIdentityCard(rule, value, callback) {
      if (!this.backIdentityCard || this.backIdentityCard[0].status === 'error') {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请上传身份证照片（背面）')
        return
      }

      callback()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.$lodash.find(this.invoice, { status: 'uploading' }) ||
        this.$lodash.find(this.frontIdentityCard, { status: 'uploading' }) ||
        this.$lodash.find(this.backIdentityCard, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          this.$set(values, 'agent_id', this.$store.getters.userAgentId)

          const data = {
            ...values
          }

          if (this.invoice === undefined) {
            delete data.invoice
          } else {
            data.invoice = this.invoice[0].response
          }

          if (this.frontIdentityCard === undefined) {
            delete data.front_identity_card
          } else {
            data.front_identity_card = this.frontIdentityCard[0].response
          }
          if (this.backIdentityCard === undefined) {
            delete data.back_identity_card
          } else {
            data.back_identity_card = this.backIdentityCard[0].response
          }

          withdrawalAgentCommission(data).then((res) => {
            if (res.code === 0) {
              this.$router.push({ name: 'withdrawals' })
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.pay-way-btn {
  height: 65px;
  width: 200px;
  margin-right: 20px;
  border-radius: 0;
}
.wechat-pay-btn {
  background: url("~@/assets/images/wechat_pay_logo.svg") no-repeat center;
}

.alipay-btn {
  background: url("~@/assets/images/alipay_logo.svg") no-repeat center;
}

.bank {
  float: right;
  width: 200px;
  padding-left: 32px;
  height: 65px;

  .left {
    margin-top: 15px;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
  }

  .right {
    margin-left:13px;
  }
}
</style>
